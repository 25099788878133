import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css'; // We'll create this file for the new styles

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-transparent text-white py-6">
      <nav className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/">
            <img src="/images/esports-logo.png" alt="E-Sports Club Logo" className="h-16 lg:h-24" />
          </Link>
          <button
            className={`menu lg:hidden ${isMenuOpen ? 'opened' : ''}`}
            onClick={toggleMenu}
            aria-label="Main Menu"
            aria-expanded={isMenuOpen}
          >
            <svg width="32" height="32" viewBox="0 0 100 100">
              <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
              <path className="line line2" d="M 20,50 H 80" />
              <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
            </svg>
          </button>
        </div>
        <div className={`nav-items ${isMenuOpen ? 'open' : ''}`}>
          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-8">
            <Link to="/" className="nav-item">Home</Link>
            <a href="#tournaments" className="nav-item">Tournaments</a>
            <a href="#our-team" className="nav-item">Our Team</a>
            <a href="#our-sponsors" className="nav-item">Our Sponsors</a>
            <Link to="/about" className="nav-item">About Us</Link>
            <a href="#shop" className="nav-item">Shop</a>
            <Link to="/contact" className="nav-item">Contact Us</Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
