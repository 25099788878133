import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import JoinClub from './components/JoinClub';
import RegisterOptions from './components/RegisterOptions';
import RegisterForm from './components/RegisterForm';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  if (!clientId) {
    console.error('REACT_APP_GOOGLE_CLIENT_ID is not set in the environment variables.');
    return (
      <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Configuration Error</h1>
          <p>Google Client ID is not configured properly.</p>
          <p className="mt-2">Please check your .env file and ensure REACT_APP_GOOGLE_CLIENT_ID is set correctly.</p>
        </div>
      </div>
    );
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <Routes>
          <Route path="/" element={<JoinClub />} />
          <Route path="/register" element={<RegisterOptions />} />
          <Route path="/register/:role" element={<RegisterForm />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;